html, body, #root, .of_community, .main_content, .content_div {
    min-height: 100% !important;
    height: 100%;
}

body {
    background: #140739;
}

* {
    box-sizing: border-box;
}

body {
    margin: 0;
    text-align: center;
    color: #000;
}

p {
    margin: unset;
}

/* scroll bar */
html::-webkit-scrollbar,
.scroll_bar::-webkit-scrollbar {
    width: 16px;
}

html::-webkit-scrollbar-thumb,
.scroll_bar::-webkit-scrollbar-thumb {
    height: 56px;
    border-radius: 8px;
    border: 4px solid transparent;
    background-clip: content-box;
    background-color: #757575;
}
